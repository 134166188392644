<template>
  <HubSelectField
    :disabled="disabled"
    v-model="selectedContainerId"
    name="container-id"
    class="modal-form__field"
    @change="onSelect()"
    :items="items"
    ref="containerId"
    :label="$t('billing.containerTypeCode')"
    :error-messages="(errorMessages && errorMessages.containerId) || []"
  />
</template>

<script>
export default {
  name: 'ContainerTypeCodeDropdown',
  components: {},
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      required: true,
    },
    errorMessages: {
      type: Object,
    },
    currentContainerId: {
      type: String,
    },
  },
  data() {
    return {
      selectedContainerId: this.currentContainerId.toString(),
    }
  },
  methods: {
    onSelect() {
      this.$emit('containerId', this.selectedContainerId.toString())
      this.$emit('reset')
    },
    clearData() {
      this.selectedContainerId = ''
    },
  },
}
</script>
