<template>
  <HubSelectField
    :disabled="disabled"
    v-model="selectedCurrency"
    name="currency"
    class="modal-form__field"
    @change="onSelect()"
    :items="items"
    ref="currency"
    :label="$t('billing.currency')"
    :error-messages="(errorMessages && errorMessages.currency) || []"
  />
</template>

<script>
export default {
  name: 'CurrencyDropdown',
  components: {},
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      required: true,
    },
    errorMessages: {
      type: Object,
    },
    currentCurrency: {
      type: String,
    },
  },
  data() {
    return {
      selectedCurrency: this.currentCurrency,
    }
  },

  methods: {
    onSelect() {
      this.$emit('currency', this.selectedCurrency)
      this.$emit('reset')
    },
    clearData() {
      this.selectedCurrency = ''
    },
  },
  watch: {
    currentCurrency: function(newValue) {
      this.selectedCurrency = newValue
    },
  },
}
</script>
