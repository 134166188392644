<template>
  <div class="fields-container">
    <ContainerTypeCodeDropdown
      ref="containTypeCodeDropdown"
      :items="containerTypeCodes"
      @reset="onInputFocus('containerId')"
      @containerId="updateContainerTypeCode($event)"
      :error-messages="errorMessages"
      :current-container-id="formData.containerId"
    />
    <RegionDropdown
      ref="regionDropdown"
      :items="operatingRegions"
      @reset="onInputFocus('originRegion')"
      @region="updateRegion($event)"
      :error-messages="errorMessages"
      :current-region="formData.originRegion"
    />
    <div class="break" />
    <CurrencyDropdown
      ref="currencyDropdown"
      :items="currencies"
      @reset="onInputFocus('currency')"
      @currency="updateCurrency($event)"
      :error-messages="errorMessages"
      :current-currency="formData.currency"
    />
    <hub-text-field
      @keyup="onInputFocus('itemRatePrice')"
      :error-messages="(errorMessages && errorMessages.itemRatePrice) || []"
      name="item-rate-price"
      v-model="formData.itemRatePrice"
      :label="$t('defaultPickRateContainerPricing.fields.itemRatePrice')"
      persistent-hint
    />
    <hub-text-field
      @keyup="onInputFocus('skuRatePrice')"
      :error-messages="(errorMessages && errorMessages.skuRatePrice) || []"
      name="sku-rate-price"
      v-model="formData.skuRatePrice"
      :label="$t('defaultPickRateContainerPricing.fields.skuRatePrice')"
      persistent-hint
    />
  </div>
</template>

<script>
import RegionDropdown from '@/components/forms/RegionDropdown.vue'
import ContainerTypeCodeDropdown from '@/components/forms/ContainerTypeCodeDropdown.vue'
import CurrencyDropdown from '@/components/forms/CurrencyDropdown.vue'

export default {
  name: 'InputFields',
  components: { ContainerTypeCodeDropdown, CurrencyDropdown, RegionDropdown },
  props: {
    formData: {
      type: Object,
    },
  },
  computed: {
    errorMessages() {
      return this.$store.getters['core/validationErrors']
    },
    currencies() {
      return this.$store.getters['billing/getOriginRegions']?.map(data => ({
        text: data.currency,
        value: data.currency,
      }))
    },
    containerTypeCodes() {
      return this.$store.getters['billing/getContainerTypeCodes']?.map(data => ({
        text: data.name,
        value: data.id.toString(),
      }))
    },
    operatingRegions() {
      return this.$store.getters['billing/getOriginRegions']?.map(data => ({
        text: data.code,
        value: data.code,
      }))
    },
  },
  methods: {
    onInputFocus(inputName) {
      this.removeError(inputName)
    },

    removeError(field) {
      this.$store.commit('core/removeError', field)
    },

    updateRegion(e) {
      this.formData.originRegion = e
    },
    updateContainerTypeCode(e) {
      this.formData.containerId = String(e)
    },
    updateCurrency(e) {
      this.formData.currency = String(e)
    },
  },
  watch: {
    formData() {
      this.$refs.regionDropdown.clearData()
      this.$refs.containTypeCodeDropdown.clearData()
      this.$refs.currencyDropdown.clearData()
    },
  },
}
</script>

<style>
.fields-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.fields-container > * {
  flex-grow: 2;
}

.break {
  flex-basis: 100%;
  height: 0;
}
</style>
