<template>
  <modal-form
    :value="value"
    id="edit-default-pick-rate-container-pricing-form"
    lazy-validation
    :loading="loading"
    :fields="formData"
    :active-field="activeField"
  >
    <template #header>
      {{ $t('defaultPickRateContainerPricing.editModal.title') }}
    </template>

    <template #fields>
      <InputFields :form-data="formData" />
    </template>

    <template #form-actions>
      <hub-button name="cancel" variant="secondary" @click="$emit('close')" id="cancelButton">
        {{ $t('common.cancel') }}
      </hub-button>
      <hub-button name="save" @click="onSubmit" id="save-button" :loading="loading">
        {{ $t('common.save') }}
      </hub-button>
    </template>
  </modal-form>
</template>

<script>
import { mapGetters } from 'vuex'
import InputFields from './InputFields.vue'
export default {
  name: 'DefaultPickRateContainerPricingEditModal',
  components: {
    InputFields,
  },
  props: {
    value: Boolean,
    selected: { type: Object, required: true },
  },

  data() {
    return {
      formData: {
        containerId: this.selected.containerId.toString() ?? '',
        originRegion: this.selected.originRegion ?? '',
        itemRatePrice: this.selected.itemRatePrice ?? '',
        skuRatePrice: this.selected.skuRatePrice ?? '',
        currency: this.selected.currency ?? '',
      },
      activeField: '',
    }
  },

  computed: {
    ...mapGetters('billing', ['getSelectedPriceListVersionId']),
    ...mapGetters('core', ['apiEndpointIsLoading', 'hasErrors']),

    loading() {
      return this.apiEndpointIsLoading({
        method: 'GET',
        url: '/pick-rate-container-default-price-list/' + this.selected?.id,
      })
    },
  },

  methods: {
    async onSubmit() {
      const res = await this.$store.dispatch('defaultPickRateContainerPricing/update', {
        id: this.selected?.id,
        data: {
          ...this.formData,
          container_id: parseInt(this.formData.containerId),
          priceListVersionId: this.getSelectedPriceListVersionId,
        },
      })

      if (res?.status !== 200) return

      this.$emit('close')
      this.$emit('updated-item')
    },
  },
}
</script>
