<template>
  <huboo-page>
    <huboo-page-heading :heading="$t('defaultPickRateContainerPricing.tablePageTitle') | title" />
    <huboo-table
      id="default-pick-rate-container-pricing"
      hide-date-picker
      hide-search
      :headers="headers"
      :items="items"
      v-bind="options"
      :server-items-length="serverItemsLength"
      :loading="isLoading"
      :remove="isAdmin"
      @row-clicked="onRowSelected"
      @update:options="handleUpdateOptions"
      @remove="openConfirmationModal"
      @confirm-delete="onRemove"
      localPagination
    >
      <template #actions>
        <v-btn
          :disabled="loading"
          color="primary"
          @click="openCreateModal"
          id="pickRateContainerDefaultPricingCreateButton"
          v-if="isAdmin"
        >
          {{ $t('defaultPickRateContainerPricing.createModal.createButton') }}
        </v-btn>
      </template>
    </huboo-table>
    <default-pick-rate-container-pricing-edit-modal
      v-if="selected"
      v-model="editModal"
      :selected="selected"
      @close="onModalClose"
      @updated-item="onUpdatedItem"
    />
    <default-pick-rate-container-pricing-create-modal
      v-model="createModal"
      @close="closeCreateModal"
      @updated-item="onUpdatedItem"
    />
  </huboo-page>
</template>

<script>
import i18n from '@/boot/i18n'
import { title } from '@/utilities/filters'
import ClientMixin from '@/mixins/client'
import ModalActionsMixin from '@/mixins/modalActions'
import VersionedPriceListMixin from '@/mixins/versionedPriceList'
import EditModal from '@/modules/DefaultPickRateContainerPricing/DefaultPickRateContainerPricingEditModal.vue'
import CreateModal from '@/modules/DefaultPickRateContainerPricing/DefaultPickRateContainerPricingCreateModal.vue'

const tableHeaders = [
  {
    text: title(i18n.t('defaultPickRateContainerPricing.fields.containerTypeCode')),
    sortable: false,
    value: 'containerCode',
  },
  {
    text: title(i18n.t('defaultPickRateContainerPricing.fields.originRegion')),
    sortable: false,
    value: 'originRegion',
  },
  {
    text: title(i18n.t('defaultPickRateContainerPricing.fields.currency')),
    sortable: false,
    value: 'currency',
  },
  {
    text: title(i18n.t('defaultPickRateContainerPricing.fields.itemRatePrice')),
    sortable: false,
    value: 'itemRatePrice',
  },
  {
    text: title(i18n.t('defaultPickRateContainerPricing.fields.skuRatePrice')),
    sortable: false,
    value: 'skuRatePrice',
  },
  {
    text: title(i18n.t('defaultPickRateContainerPricing.fields.updatedAt')),
    sortable: true,
    value: 'updatedAt',
  },
]
const fetchContainerTypeUrl = 'container-type-codes'

export default {
  name: 'defaultPickRateContainerPricing',
  mixins: [ClientMixin, ModalActionsMixin, VersionedPriceListMixin],
  components: {
    'default-pick-rate-container-pricing-edit-modal': EditModal,
    'default-pick-rate-container-pricing-create-modal': CreateModal,
  },
  data() {
    return {
      editModal: false,
      createModal: false,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
      selected: null,
    }
  },

  computed: {
    headers() {
      return tableHeaders
    },
    containerTypeCodes() {
      return this.$store.getters['billing/getContainerTypeCodes']?.map(data => ({
        text: data.name,
        value: data.id,
      }))
    },

    defaultPickRateContainerPricingList() {
      const list = this.$store.getters['defaultPickRateContainerPricing/getAll']
      if (Array.isArray(list))
        return list.map(l => ({
          ...l,
          loading: this.isDeleteLoading(l.id),
        }))
      else return []
    },

    isLoading() {
      return (
        this.$store.getters['core/apiEndpointIsLoading']({
          method: 'GET',
          url: fetchContainerTypeUrl,
        }) || this.loading
      )
    },

    items() {
      return this.defaultPickRateContainerPricingList.map(item => {
        return {
          ...item,
          itemRatePrice: this.formatPrice(item.itemRatePrice, 2),
          skuRatePrice: this.formatPrice(item.skuRatePrice, 2),
          createdAt: this.formatDate(item.createdAt),
          updatedAt: this.formatDate(item.updatedAt),
          containerCode: this.getContainerTypeCode(item.containerId),
        }
      })
    },

    meta() {
      return (
        this.$store.getters['defaultPickRateContainerPricing/getMeta'] || {
          total: this.items.length,
        }
      )
    },

    url() {
      return '/pick-rate-container-default-price-list/'
    },

    dataReady() {
      return !this.loading && this.getSelectedPriceListVersionId
    },
  },

  methods: {
    fetch() {
      if (this.dataReady) {
        const { options } = this
        const { page } = options
        const params = {
          page: page || undefined,
          priceListVersion: this.getSelectedPriceListVersionId,
        }
        if (page) params.page = page
        this.$store.dispatch('defaultPickRateContainerPricing/fetchCollection', {
          params,
        })
      }
    },

    async onRemove() {
      await this.$store
        .dispatch('defaultPickRateContainerPricing/delete', this.selected.id)
        .finally(() => {
          this.fetch()
          this.selected = ''
        })
    },

    isDeleteLoading(id) {
      return this.$store.getters['core/apiEndpointIsLoading']({
        url: this.url + id,
        method: 'DELETE',
      })
    },

    getContainerTypeCode(id) {
      return this.containerTypeCodes?.find(code => code.value === id)?.text
    },
  },

  watch: {
    options: {
      handler() {
        this.fetch()
      },
      deep: true,
      immediate: true,
    },

    getSelectedPriceListVersionId() {
      this.fetch()
    },
  },

  created() {
    this.$store.dispatch('billing/fetchContainerTypeCodes', { url: fetchContainerTypeUrl })
  },
}
</script>

<style lang="scss" scoped></style>
