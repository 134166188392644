<template>
  <modal-form
    :value="value"
    id="create-default-pick-rate-container-pricing-form"
    lazy-validation
    :loading="loading"
    :fields="formData"
    :active-field="activeField"
  >
    <template #header>
      {{ $t('defaultPickRateContainerPricing.createModal.title') }}
    </template>

    <template #fields>
      <InputFields :form-data="formData" />
    </template>

    <template #form-actions>
      <hub-button name="cancel" variant="secondary" @click="closeAndClear" id="cancelButton">
        {{ $t('common.cancel') }}
      </hub-button>
      <hub-button name="save" @click="onSubmit" id="save-button" :loading="loading">
        {{ $t('common.save') }}
      </hub-button>
    </template>
  </modal-form>
</template>

<script>
import { mapGetters } from 'vuex'
import InputFields from './InputFields.vue'
export default {
  name: 'defaultPickRateContainerPricingCreateModal',
  components: {
    InputFields,
  },
  props: {
    value: Boolean,
  },
  data() {
    return {
      formData: {
        containerId: '',
        originRegion: '',
        itemRatePrice: '',
        skuRatePrice: '',
        currency: '',
      },
      activeField: '',
    }
  },

  computed: {
    ...mapGetters('billing', ['getSelectedPriceListVersionId']),

    loading() {
      return this.$store.getters['core/apiEndpointIsLoading']({
        method: 'GET',
        url: '/pick-rate-container-default-price-list/',
      })
    },

    hasErrors() {
      return this.$store.getters['core/hasErrors']
    },

    fields() {
      return Object.keys(this.formData)
    },
  },

  watch: {
    selected: function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.formData = this.convertedObject(newVal)
      }
    },
  },

  methods: {
    async onSubmit() {
      const res = await this.$store.dispatch('defaultPickRateContainerPricing/create', {
        data: {
          ...this.formData,
          container_id: parseInt(this.formData.containerId),
          priceListVersionId: this.getSelectedPriceListVersionId,
        },
      })

      if (res?.status !== 201) return

      this.$emit('close')
      this.$emit('updated-item')
      this.formData = {}
    },

    closeAndClear() {
      this.$emit('close')
      this.formData = {}
    },
  },
}
</script>
